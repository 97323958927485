import React from "react";

function Contact() {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-[#F2E9DC] text-[#00000] :"
    >
      <div className="flex flex-col justify-center items-center w-full h-full ">
        {/* container */}
        <div className="flex flex-col w-[300px] sm:flex-row sm:place-items-center sm:w-[600px] sm:gap-40 ">
          {/* left side of grid */}
          <div className="text-center text-lg font-bold my-10 ">
            <h2 className="text-4xl ">contact</h2>
          </div>
          {/* mobile */}

          <form method='POST'action="https://getform.io/f/a75e3a46-1c88-45dc-ae3e-6072eab274e8" className=" flex-col sm:hidden">
            <div className="flex flex-col gap-1">
            <p className="mb-1">named</p>
            <input type="text" name="name" />
            <p className="mb-1">email</p>
            <input type="text" name="email" />
            <p className="mb-1">message</p>
            <textarea name="message" rows={6} id=""></textarea>
            <button className=" bg-[#808F85] px-3 py-3 my-2 mx-auto flex flex-col items-center rounded-lg hover:bg-inherit ">
              lets collaborate
            </button>
            </div>
          </form>

          {/* tablet desktop */}
          <form method='POST'action="https://getform.io/f/a75e3a46-1c88-45dc-ae3e-6072eab274e8" className=" hidden sm:flex sm:flex-col sm:w-[500px]">
            <div className="flex flex-col gap-1">
            <p className="mb-1">name</p>
            <input type="text" name="name" />
            <p className="mb-1">email</p>
            <input type="text" name="email" />
            <p className="mb-1">message</p>
            <textarea name="message" rows={4} id=""></textarea>
            <button className=" bg-[#808F85] px-3 py-3 my-2 mx-auto flex flex-col items-center rounded-lg hover:bg-inherit ">
              lets collaborate
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
