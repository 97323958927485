import React, { useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { CiMenuFries } from "react-icons/ci";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[100px] flex justify-between items-center px-4 bg-[#F2E9DC] text-[#00000]">
      <div className=" z-10">
        <h1 className="text-3xl sm:text-3xl">joel becerra</h1>
      </div>

      {/* menu */}

      <ul className=" hidden md:flex">
        <li>
          <Link
            to="home"
            smooth={true}
            duration={500}
          >
            home
          </Link>
        </li>
        <li>
          <Link
            to="skills"
            smooth={true}
            duration={500}
          >
            skills
          </Link>
        </li>
        <li>
          <Link
            to="work"
            smooth={true}
            duration={500}
          >
            work
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            smooth={true}
            duration={500}
          >
            contact
          </Link>
        </li>
      </ul>

      {/* mobile menu */}

      <div
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#F2E9DC] flex flex-col justify-center items-start"
        }
      >
        <ul
          className={
            !nav
              ? "hidden"
              : "absolute top-0 left-0 w-full h-screen bg-[#F2E9DC] flex flex-col justify-center items-start"
          }
        >
          <li className=" py-6 text-4xl pl-10"><Link
          onClick={handleClick}
            to="home"
            smooth={true}
            duration={500}
          >
            home
          </Link></li>
          <li className=" py-6 text-4xl pl-10">
            <Link
            onClick={handleClick}
            to="skills"
            smooth={true}
            duration={500}
          >
            skills
          </Link></li>
          <li className=" py-6 text-4xl pl-10">
            <Link
            onClick={handleClick}
            to="work"
            smooth={true}
            duration={500}
          >
            work
          </Link></li>
          <li className=" py-6 text-4xl pl-10">
             <Link
             onClick={handleClick}
            to="contact"
            smooth={true}
            duration={500}
          >
            contact
          </Link></li>
        </ul>
      </div>

      {/* hamburger */}

      <div onClick={handleClick} className=" md:hidden z-10">
        {!nav ? <CiMenuFries size="35px" /> : <LiaTimesSolid size="35px" />}
      </div>

      {/* social icons */}

      <div className=" hidden"></div>
    </div>
  );
};

export default Navbar;
