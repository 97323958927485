import React from 'react'

function Work() {
  return (
    <div name="work" className="w-full h-screen bg-[#F2E9DC] text-[#00000]">
      <div className="flex flex-col justify-center items-center w-full h-full">
        {/* container */}
        <div className="flex flex-col  sm:flex-row sm:place-items-center ">
          {/* left side of grid */}
          <div className="text-center text-lg font-bold my-10 sm:">
            <p className="text-4xl sm:pl-20">
              work
            </p>
          </div>
          {/* mobile */}
          <div className="flex flex-col space-y-4 text-center sm:hidden">
            <h2 className="mb-5 text-lg ">more work incoming 🤓</h2>
          </div>

          {/* tablet desktop */}
          <div className="hidden sm:block pl-20">
          <h2 className="text-lg ">more <br />work <br />incoming 🤓</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work