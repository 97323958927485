import React from 'react'
import Html from '../assets/html.svg'
import Css from '../assets/css.svg'
import Reeact from '../assets/react.svg'
import Tailwind from '../assets/tailwind.svg'
import Nodee from '../assets/node.svg'
import Javascriptt from '../assets/js.svg'

function Skills() {
  return (
    <div name="skills" className="w-full h-screen bg-[#F2E9DC] text-[#00000]">
      <div className="flex flex-col justify-center items-center w-full h-full">
        {/* container */}
        <div className="flex flex-col  sm:flex-row sm:place-items-center ">
          {/* left side of grid */}
          <div className="text-center text-lg font-bold my-10 sm:">
            <p className="text-4xl sm:pl-20">
              skills
            </p>
          </div>
          {/* mobile */}
          <div className="flex flex-col space-y-10 text-center sm:hidden ">
            <p>
            My main stack currently is React in combination with
            <br />Tailwind CSS but not limited to.
            </p>
            <div className='flex flex-row justify-center gap-10'>
            <img className='size-14' src={Html} alt="" />
            <img className='size-14' src={Css} alt="" />
            <img className='size-14' src={Reeact} alt="" />
            </div>
            <div className='flex flex-row justify-center gap-10 mt-6'>
            <img className='size-14' src={Tailwind} alt="" />
            <img className='size-14' src={Nodee} alt="" />
            <img className='size-14' src={Javascriptt} alt="" />
            </div>
          </div>

          {/* tablet desktop */}
          <div className="hidden sm:block pl-20 ">
            <p>
            My main stack currently is React in combination with Tailwind CSS.
            </p>
            <div className='mt-8'>
            <div className='flex flex-row justify-center gap-10'>
            <img className='size-14' src={Html} alt="" />
            <img className='size-14' src={Css} alt="" />
            <img className='size-14' src={Reeact} alt="" />
            </div>
            <div className='flex flex-row justify-center gap-10 mt-6'>
            <img className='size-14' src={Tailwind} alt="" />
            <img className='size-14' src={Nodee} alt="" />
            <img className='size-14' src={Javascriptt} alt="" />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills