import React from "react";
import Hero from "../assets/portrait.png";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#F2E9DC]">
      {/* Container */}
      <div className="flex flex-col justify-center items-center h-full sm:flex-row ">
        <div className=" flex flex-col place-items-center sm:flex sm:place-items-start">
          <p className=" text-[#000000]">Hi, my name is</p>
          <h2 className="text-4xl sm:text-5xl ">Joel Becerra</h2>
          <h2 className="text-4xl sm:text-5xl text-[#C75146]">
            I'm a Web Developer
          </h2>
          <p className="flex flex-col place-items-center py-2 max-w-[700px]">
            based in Fort Worth, Texas 📍
          </p>
          <p>
            I’m passionate about creating and developing <br />a clean ui/ux for
            users and my clients.
          </p>
          <button className=" bg-[#808F85] px-3 py-4 mt-7 flex items-center rounded-full hover:bg-inherit">
            view résumé
          </button>
        </div>
        <div>
          <img
            className="w-[200px] pt-14 sm:ml-5 md:ml-20 md:pt-0"
            src={Hero}
            alt="A self portrait image of Joel Becerra a web developer from fort worth texas"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
